import React from 'react'
import HeroGradient from 'components/hero-image/hero-image-gradient'
import { Helmet } from 'react-helmet'
import Floater from 'components/floating-grid'
import Layout from 'components/layout'
import Content from 'components/category/content'
import { graphql } from 'gatsby'

const items = [
  {
    link: '/implements/utility-tractors/seeding-equipment/broadcast-spreaders/',
    imageId: 'broadcastSpreadersImage',
    name: 'Broadcast Spreaders',
    alt: 'Broadcast Spreaders',
  },
  {
    link: '/implements/utility-tractors/seeding-equipment/conservation-seeders/',
    imageId: 'conservationSeedersImage',
    name: 'Conservation Seeders',
    alt: 'Conservation Seeders',
  },
  {
    link: '/implements/utility-tractors/seeding-equipment/end-wheel-grain-drills/',
    imageId: 'endWheelGrainDrillsImage',
    name: 'End-Wheel Grain Drills',
    alt: 'End-Wheel Grain Drills',
  },
  {
    link: '/implements/utility-tractors/seeding-equipment/overseeders/',
    imageId: 'overseedersImage',
    name: 'Overseeders',
    alt: 'Overseeders',
  },
  {
    link: '/implements/utility-tractors/seeding-equipment/pendular-spreaders/',
    imageId: 'pendularSpreadersImage',
    name: 'Pendular Spreaders',
    alt: 'Pendular Spreaders',
  },
]

const SeedingEquipmentPage = ({ data }) => {
  const { heroImage } = data
  const gridItems = items.map(item => {
    item.image = data[item.imageId]
    return item
  })
  return (
    <Layout>
      <Helmet>
        <title>Utility Tractor Implements - Seeding Equipment | Hutson Inc</title>
        <meta
          name='description'
          content='Spreading seeds for your lawn or property has never been easier. Check out our full line-up of Frontier seeding equipment for John Deere utility tractors at Hutson.'
        />
        <script type='application/ld+json'>
          {JSON.stringify({
            '@context': 'http://schema.org',
            '@type': 'BreadcrumbList',
            'itemListElement': [
              {
                '@type': 'ListItem',
                'position': 1,
                'name': 'Hutson Inc',
                'item': 'https://www.hutsoninc.com/',
              },
              {
                '@type': 'ListItem',
                'position': 2,
                'name': 'Implements',
                'item': 'https://www.hutsoninc.com/implements/',
              },
              {
                '@type': 'ListItem',
                'position': 3,
                'name': 'Utility Tractors',
                'item': 'https://www.hutsoninc.com/implements/utility-tractors/',
              },
              {
                '@type': 'ListItem',
                'position': 4,
                'name': 'Seeding Equipment',
                'item': 'https://www.hutsoninc.com/implements/utility-tractors/seeding-equipment/',
              },
            ],
          })}
        </script>
      </Helmet>

      <HeroGradient image={heroImage} heading='Seeding Equipment' />

      <Content>
        <Floater items={gridItems} />
      </Content>
    </Layout>
  )
}

export const pageQuery = graphql`
  {
    heroImage: file(
      relativePath: { eq: "implements/utility-tractors/seeding-equipment-header.jpg" }
    ) {
      ...FullWidthImage
    }
    broadcastSpreadersImage: file(
      relativePath: { eq: "implements/utility-tractors/seeding-equipment/broadcast-spreaders.jpg" }
    ) {
      ...FloatingGridImage
    }
    conservationSeedersImage: file(
      relativePath: { eq: "implements/utility-tractors/seeding-equipment/conservation-seeders.jpg" }
    ) {
      ...FloatingGridImage
    }
    endWheelGrainDrillsImage: file(
      relativePath: {
        eq: "implements/utility-tractors/seeding-equipment/end-wheel-grain-drills.jpg"
      }
    ) {
      ...FloatingGridImage
    }
    overseedersImage: file(
      relativePath: { eq: "implements/utility-tractors/seeding-equipment/overseeders.jpg" }
    ) {
      ...FloatingGridImage
    }
    pendularSpreadersImage: file(
      relativePath: { eq: "implements/utility-tractors/seeding-equipment/pendular-spreaders.jpg" }
    ) {
      ...FloatingGridImage
    }
  }
`

export default SeedingEquipmentPage
